import React, { lazy, Suspense, useEffect, useState } from "react"
import { graphql } from "gatsby"
import usePageLoading from 'components/utils/usePageLoading'

import { TemplateContextProvider } from 'components/post/TemplateProvider'
import { PlacesContextProvider } from 'components/post/PlacesProvider'

import SEO from "components/seo"
import Header from "components/header"
import ImpactArea from "components/sections/impactArea"
import Sections from "components/sections"
import Footer from "components/footer"

const CitiesCardsBlock = lazy(() => import("components/post/blocks/citiesCardBlock"))
const Filters = lazy(() => import("components/filters/Filters"))

import "styles/pages.scss"
import 'components/postIndex/postIndex.scss'

const FeaturedIndex = ({ data, ...props }) => {

  const pageData = data.allWpPage.edges[0].node
  const { activity } = props.params

  const pageLoader = usePageLoading()

  // TODO: Add this to the Wordpress template
  const cityBlockData = {
    heading: 'Cities & Destinations',
    bodyText: 'Explore near by cities to visit',
  }

  // Pass through pre-fectched page data to template
  const posts = props.pageContext.posts ? props.pageContext.posts : []

  return (
    <div className={`page featured ${pageLoader}`}>
      <TemplateContextProvider
        activity={activity}
        page={props.location.pathname}
        posts={posts}>
        <PlacesContextProvider>
          <SEO
            lang="en-US"
            title={data.allWpPage.edges[0].node.seo.title}
            data={data.allWpPage.edges[0].node.seo} />
          <main className='post-index featured'>
            <Header />
            <ImpactArea
              data={data.allWpPage.edges[0].node}
              showCityTitle={true}
              />
            <div className="post-body post-index">
              <div className="container">
                <Suspense fallback={<span>...</span>}>
                  <Filters
                    showActivities={true}
                    showCities={false}
                    showVibes={true}
                    showAllLink={false}
                    size='small' />
                </Suspense>

                <div className='body'>

                  {pageData.sections.sections &&
                    <Sections
                      data={pageData.sections.sections}
                    />
                  }

                  <Suspense fallback={<span>...</span>}>
                    <CitiesCardsBlock
                      data={cityBlockData}
                      sort={true}
                    />
                  </Suspense>

                </div>
              </div>
            </div>
          </main>
          <Footer />
        </PlacesContextProvider>
      </TemplateContextProvider>
    </div>
  )
}

//FeaturedIndex.whyDidYouRender = true
export default FeaturedIndex

export const pageQuery = graphql`
  query FeaturedQuery {
    allWpPage(filter: {slug: {in: "featured"}}) {
      edges {
        node {
          id
          databaseId
          title
          date
          title
          seo {
            title
            opengraphAuthor
            focuskw
            canonical
            opengraphDescription
            metaRobotsNoindex
            metaRobotsNofollow
            metaDesc
            metaKeywords
            opengraphImage {
              mediaItemUrl
            }
            opengraphModifiedTime
            opengraphPublishedTime
            opengraphPublisher
            opengraphSiteName
            opengraphTitle
            opengraphType
            opengraphUrl
          }
          impactArea {
            textColor
            backgroundColor
            backgroundType
            backgroundImage {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    layout: CONSTRAINED
                  )
                }
                publicURL
              }
              mediaItemUrl
            }
            heading
            vibeset {
              ... on WpVibeset {
                id
                title
                databaseId
                vibesetDetails {
                  gradientImage {
                    mediaItemUrl
                  }
                  gradientVideo {
                    mediaItemUrl
                  }
                }
              }
            }
          }
          sections {
            fieldGroupName
            sections {
              ... on WpPage_Sections_Sections_VibesBlock {
                fieldGroupName
                customVibes
                heading
                style
                vibes {
                  name
                  description
                  slug
                  vibeDetails {
                    vibes {
                      name
                      slug
                    }
                  }
                }
              }
              ... on WpPage_Sections_Sections_SingCardsBlock {
                fieldGroupName
                singCards {
                  fieldGroupName
                  heading
                  editorial
                  posts {
                    categoryQuery
                    geoQuery {
                      latitude
                      longitude
                    }
                    numOfPlaces
                    placeType
                    vibes
                    vibeQuery {
                      name
                      slug
                    }
                    type
                    title
                    subcategory
                    placeId
                    fieldGroupName
                    distanceQuery
                    editorialCategoryQuery
                    description
                    city
                  }
                  showAll
                  showAllLink
                  showAllRelation
                  showAllText
                  vibemapSuggests
                  subheading
                  style
                }
              }
              ... on WpPage_Sections_Sections_PostCardsBlock {
                automatic
                fieldGroupName
                heading
                showAll
                subHeading
                vibeQuery {
                  name
                  slug
                }
              }
              ... on WpPage_Sections_Sections_NeighborhoodCardsBlock {
                fieldGroupName
                neighborhoodCards {
                  automatic
                  fieldGroupName
                  heading
                  neighborhoodPosts {
                    ... on WpNeighborhood {
                      id
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
